import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import Button from '@dqp/common/components/Button/Button'
import { spacer } from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import Link from '@dqp/common/components/Link/Link'

const styles = {
  img: ({ imgURL, imgHeight }) => css`
    border-radius: 8px;
    width: 100%;
    height: 420px;
    background: url(${imgURL});
    background-position: center center;
    background-size: cover;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    height: ${imgHeight}px;
    ${spacer.mrB20};
  `,
  title: css`
    ${spacer.mrB5};
  `,
  text: css`
    line-height: 150%;
    ${spacer.mrB20}
  `,
}
function ArticleCard({
  slug,
  title,
  excerpt,
  noButton,
  titleColor,
  textColor,
  imgHeight,
  featuredImage,
  className,
}) {
  return (
    <Link to={`/${slug}`} className='d-block' css={className}>
      <div css={styles.container}>
        {featuredImage && (
          <div
            css={styles.img({
              imgURL: featuredImage,
              imgHeight,
            })}
          />
        )}
        <Title
          as='h2'
          weight='bold'
          size='large'
          color={titleColor || textColor}
          css={styles.title}
        >
          {title}
        </Title>
        <Text size='small' color={textColor} css={styles.text}>
          {excerpt}
        </Text>
        {!noButton && (
          <Button variant='black' size='small'>
            READ MORE
          </Button>
        )}
      </div>
    </Link>
  )
}
ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  noButton: PropTypes.bool,
  textColor: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  imgHeight: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  featuredImage: PropTypes.string,
  excerpt: PropTypes.string.isRequired,
  className: PropTypes.string,
}
ArticleCard.defaultProps = {
  noButton: false,
  titleColor: '',
  className: '',
  featuredImage: '',
}
export default ArticleCard
