import React from 'react'
import PropTypes from 'prop-types'
import {
  colors,
  spacer,
  breakpoints,
  mixins,
} from '@dqp/common/styles/utilities'
import { css } from '@emotion/core'
import ArticleCard from './ArticleCard'

const styles = {
  row: css`
    ${breakpoints({
      marginBottom: [0, 0, 0, 40, 50],
    })};
  `,
}
function ArticlesGrid({ articles }) {
  const articles2dGrid = []
  for (let i = 0; i < articles.length; i += 3) {
    articles2dGrid.push(articles.slice(i, i + 3))
  }
  return (
    <section>
      <div className='container relative' css={spacer.padT60}>
        {articles2dGrid.map((articlesRow, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className='row' key={i} css={styles.row}>
            {articlesRow.map(article => (
              <div key={article.slug} className='col-lg-4'>
                <ArticleCard
                  imgHeight='200'
                  noButton
                  titleColor={colors.black}
                  textColor={colors.charcoal}
                  css={css`
                    :hover {
                      opacity: 0.7;
                    }
                    ${mixins.transition()}
                  `}
                  {...article}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  )
}
ArticlesGrid.propTypes = {
  articles: PropTypes.array.isRequired,
}

export default ArticlesGrid
